
  import * as Sentry from '@sentry/browser'

  export default {
    /**
     * This page is never really shown, but we need a page that does
     * nothing but redirect if the user is logged in. The authentication
     * module will take care of logging out and redirect the user  to
     * the login page if it is not the case. We have to **absolutely avoid**
     * to do it twice or the user will get stuck in a loop.
     */
    name: `AuthErrorPage`,
    head() {
      return {
        meta: [
          {
            [`http-equiv`]: `refresh`,
            content: `2; URL=${this.$config.public.baseUrl}`
          }
        ]
      }
    },
    mounted() {
      const hydraError = this.$route.query.error_description
      console.error(`HYDRA error: ${hydraError}`)
      Sentry.captureMessage(`HYDRA error: ${hydraError}`)
    }
  }
